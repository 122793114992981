import '../styles';

setBaseFontSize();

function setBaseFontSize() {
    const root = document.querySelector('html');
    const baseFontSize = Number(getComputedStyle(root).fontSize.slice(0, -2));
    const baseFontSize100 = (100 * baseFontSize) / 62.5;
    document.documentElement.style.setProperty(
        '--base-font-size',
        `${baseFontSize100}px`
    );
}
